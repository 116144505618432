import {
  ListBulletIcon,
  MagnifyingGlassIcon,
  SparklesIcon,
  TrophyIcon,
} from '@heroicons/react/16/solid'
import clsx from 'clsx'
import { Session } from 'next-auth'
import Link from 'next/link'
import {
  EnMenuItemType,
  MenuItemLink,
} from 'src/components/common/MenuItem/types'
import { LogoIcon } from 'src/components/icons'
import { getCatalogUrl } from 'src/helpers/global.helper'
import { t } from 'src/helpers/translate.helper'
import HavContainer from './NavContainer'
import UserContainer from './UserContainer'

export const getBaseMenuItems = (
  isAuth: boolean,
  isHeaderMenu?: boolean,
): MenuItemLink[] => [
  {
    type: EnMenuItemType.LINK,
    title: t('Search'),
    href: '/search',
    className: isHeaderMenu ? 'md:order-4' : '',
    icon: <MagnifyingGlassIcon className="w-4" />,
  },
  {
    type: EnMenuItemType.LINK,
    title: t('Catalog'),
    className: isHeaderMenu ? 'md:order-1' : '',
    href: getCatalogUrl(),
    icon: (
      <ListBulletIcon className={clsx('w-4', { 'md:hidden': isHeaderMenu })} />
    ),
  },
  {
    type: EnMenuItemType.LINK,
    className: isHeaderMenu ? 'md:order-2' : '',
    title: t('Top'),
    href: '/top',
    icon: <TrophyIcon className={clsx('w-4', { 'md:hidden': isHeaderMenu })} />,
  },
  ...(isAuth
    ? ([
        {
          type: EnMenuItemType.LINK,
          title: t('Subscription'),
          className: isHeaderMenu ? 'md:order-3' : '',
          href: '/subscription',
          icon: (
            <SparklesIcon
              className={clsx('w-4', { 'md:hidden': isHeaderMenu })}
            />
          ),
        },
      ] as MenuItemLink[])
    : []),
]

export default function Header(props: { session: Session | null }) {
  return (
    <div className="flex auto-cols-auto h-12 items-center gap-x-4 mx-auto max-w-7xl md:px-4">
      <Link
        className="w-12 aspect-square grid place-content-center"
        scroll
        href="/"
      >
        <LogoIcon className="flex-shrink-0" />
      </Link>

      <HavContainer session={props.session} />

      <UserContainer session={props.session} />
    </div>
  )
}
