import {
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import {
  ArrowRightIcon,
  BookmarkIcon,
  Cog6ToothIcon,
} from '@heroicons/react/16/solid'
import { MoonIcon, SunIcon, UserIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Session } from 'next-auth'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { Fragment, memo, useContext, useEffect, useState } from 'react'
import { fetcher } from 'src/app/lib/fetcher'
import { handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { Icon } from 'src/components/common/Icon'
import MenuItem from 'src/components/common/MenuItem'
import {
  EnMenuItemType,
  MenuItemButton,
  MenuItemType,
} from 'src/components/common/MenuItem/types'
import { UserCircleImage } from 'src/components/common/UserCircleImage'
import { Button } from 'src/components/ui/Button'
import { EnMethod, EnModal, EnTheme } from 'src/enums'
import { getImageSrc } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import { getAdminSocket } from 'src/instances/admin-socket'
import { ThemeContext } from 'src/providers/ThemeProvider'
import { getBaseMenuItems } from '../index'

export const UserPopover = memo(function UserPopover(props: {
  session: Session | null
  toggleModal: (value: EnModal | null) => void
}) {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const pathname = usePathname()
  const router = useRouter()
  const IconComponent = theme === EnTheme.DARK ? SunIcon : MoonIcon

  const [notifications, setNotifications] = useState({
    hasImportant: false,
    count: 0,
  })

  useEffect(() => {
    if (!props.session) return

    fetcher<{ count: number; hasImportant: boolean }>({
      url: `/notifications/count`,
      params: {
        method: EnMethod.GET,
      },
    })
      .then((data) => {
        setNotifications(data)
      })
      .catch(handleExceptionRequest)
  }, [props.session])

  useEffect(() => {
    if (!props.session) return

    const socket = getAdminSocket(props.session?.user.accessToken)
    if (!socket) return

    socket.on('frontend.read-notification', (data: { count: number }) =>
      setNotifications((notifications) => ({
        ...notifications,
        count: notifications.count - data.count,
      })),
    )

    socket.on('frontend.add-notification', (data: { isImportant: boolean }) => {
      setNotifications((notifications) => ({
        hasImportant: data.isImportant ?? notifications.hasImportant,
        count: notifications.count + 1,
      }))

      if (window.location.pathname === '/user/notifications') {
        router.push(`/user/notifications/?refreshId=${new Date().getTime()}`)
      }
    })
  }, [props.session, router])

  const items: MenuItemType[] = [
    {
      type: EnMenuItemType.SEPARATOR,
    },
    ...(props.session
      ? [
          {
            type: EnMenuItemType.LINK,
            title: t('Bookmarks'),
            icon: <BookmarkIcon className="w-4" />,
            href: '/user/bookmarks',
          },
          {
            type: EnMenuItemType.LINK,
            title: t('Notifications'),
            icon: <Icon name="bell" className="!w-4" />,
            href: '/user/notifications',
            subTitle: notifications.count ? (
              <div className="grid justify-end">
                <span
                  className={clsx(
                    'rounded-full font-medium text-white dark:text-black-1000 grid place-content-center aspect-square w-5 text-[12px]',
                    {
                      'bg-primary': !notifications.hasImportant,
                      'bg-danger': notifications.hasImportant,
                    },
                  )}
                >
                  {notifications.count}
                </span>
              </div>
            ) : undefined,
          },
          {
            type: EnMenuItemType.LINK,
            title: t('Settings'),
            icon: <Cog6ToothIcon className="w-4" />,
            href: '/user/settings',
          },
        ]
      : []),
    {
      type: EnMenuItemType.SEPARATOR,
      className: !props.session ? 'hidden' : '',
    },
    {
      type: EnMenuItemType.BUTTON,
      title: t('Site theme'),
      icon: <IconComponent className="w-4" />,
      onClick: () => toggleTheme(),
      subTitle: <span className="grid justify-end">{t(theme)}</span>,
    },
    { type: EnMenuItemType.SEPARATOR },
    ...getBaseMenuItems(!!props.session),
    {
      type: EnMenuItemType.SEPARATOR,
      className: !props.session ? 'hidden' : '',
    },
    ...(props.session
      ? ([
          {
            type: EnMenuItemType.BUTTON,
            title: t('Logout'),
            className: '!text-danger',
            icon: <Icon name="logIn" className="w-4" />,
            isActive: false,
            onClick: () => signOut(),
          },
        ] as MenuItemButton[])
      : []),
  ]

  const renderNotifications = (isMobile: boolean) => (
    <span
      className={clsx(
        'rounded-full duration-200 group-hover:scale-0 text-white dark:text-black-1000 grid place-content-center top-1 right-0.5 aspect-square w-4 font-medium absolute leading-3 text-[9px]',
        {
          'md:hidden !right-1 !top-1.5': isMobile,
          'bg-primary': !notifications.hasImportant,
          'bg-danger': notifications.hasImportant,
        },
      )}
    >
      {notifications.count >= 100 ? '99+' : notifications.count}
    </span>
  )

  return (
    <Popover
      className={clsx('relative gap-x-1 grid grid-flow-col', {
        'md:hidden': !props.session,
      })}
    >
      {props.session && (
        <Link
          href="/user/notifications"
          className={clsx(
            'hidden group relative md:grid hover h-full cursor-pointer items-center md:hover:text-primary md:dark:hover:bg-black-600 md:hover:bg-gray-200/50 px-2.5',
            {
              'text-primary bg-gray-200/50 dark:bg-black-600':
                pathname === '/user/notifications',
              'text-secondary': pathname !== '/user/notifications',
            },
          )}
        >
          <Icon name="bell" className="w-5" />
          {notifications.count > 0 && renderNotifications(false)}
        </Link>
      )}

      <PopoverButton className="w-12 aspect-square outline-none grid place-content-center dark:hover:bg-black-600 hover:bg-gray-200/50">
        {!props.session && (
          <UserIcon className="w-6 md:hidden text-gray-primary" />
        )}
        {props.session && (
          <>
            <UserCircleImage
              className="w-7"
              username={props.session.user.username}
              subscriptionType={
                props.session.user.activeSubscription?.subscriptionType
              }
              image={getImageSrc(props.session.user.image)}
              width={28}
              height={28}
            />

            {notifications.count > 0 && renderNotifications(true)}
          </>
        )}
      </PopoverButton>

      <PopoverBackdrop
        transition
        className="fixed h-screen inset-0 bg-black-1000/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="max-md:translate-x-full opacity-0 md:-translate-y-1"
        enterTo="max-md:translate-x-0 opacity-100 md:translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="max-md:translate-x-0 opacity-100 md:translate-y-0"
        leaveTo="max-md:translate-x-full opacity-0 md:-translate-y-1"
      >
        <PopoverPanel className="popover overflow-y-auto md:absolute max-md:h-screen md:top-full md:mt-1 max-md:fixed right-0 max-md:inset-y-0 md:rounded-sm2 w-[80vw] md:w-[270px] z-10">
          {({ close }) => (
            <div className="grid gap-y-1.5 py-1.5">
              {!props.session && (
                <Button
                  withLabel
                  className="grid-cols-[auto_1fr] mx-3 my-3"
                  iconType="logIn"
                  title={t('Login')}
                  onClick={() => {
                    props.toggleModal(EnModal.AUTH)
                    close()
                  }}
                />
              )}

              {props.session && (
                <Link
                  onClick={() => close()}
                  href="/user/profile"
                  className={clsx(
                    'grid hover grid-cols-[auto_1fr] hover:bg-gray-200 hover:dark:bg-black-700 items-center py-2 gap-x-2.5 px-5',
                    {
                      'bg-gray-200 dark:bg-black-700':
                        pathname === '/user/profile',
                    },
                  )}
                >
                  <UserCircleImage
                    width={40}
                    height={40}
                    username={props.session.user.username}
                    image={getImageSrc(props.session.user.image)}
                    subscriptionType={
                      props.session.user.activeSubscription?.subscriptionType
                    }
                    className="w-10"
                  />
                  <div className="grid">
                    <span
                      className={clsx('line-clamp-1', {
                        'text-primary': pathname === '/user/profile',
                      })}
                    >
                      {props.session.user.username}
                    </span>
                    <span className="grid grid-flow-col text-xs gap-x-1.5 text-gray-primary items-center justify-start">
                      {t('My profile')}
                      <ArrowRightIcon className="w-3" />
                    </span>
                  </div>
                </Link>
              )}

              <div className="grid">
                {items.map((item, key) => (
                  <MenuItem
                    key={key}
                    item={{
                      ...item,
                      isActive:
                        item.type === EnMenuItemType.LINK &&
                        item.href === pathname,
                      onClick: () => {
                        item.onClick?.()
                        close()
                      },
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  )
})
