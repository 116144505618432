import clsx from 'clsx'

import { Fragment } from 'react'
import { Icon } from 'src/components/common/Icon'
import { IconType } from 'src/components/common/Icon/types'

export const Button = (props: {
  title: string
  withLabel?: boolean
  type?: 'submit' | 'button'
  size?: 'lg' | 'base' | 'md' | 'sm' | 'xs'
  outline?: boolean
  isLoading?: boolean
  iconType?: IconType
  disabled?: boolean
  rounded?: boolean
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'transparent'
  className?: string

  onClick?: () => void
}) => {
  const { type = 'submit', iconType = 'success', size = 'md' } = props

  const getColorClass = () => {
    switch (props.color) {
      case 'info':
        return 'bg-info border-info hover:bg-info/[0.85] hover:border-info/[0.85]'
      case 'success':
        return 'dark:bg-success-dark bg-success border-success dark:border-success-dark dark:hover:bg-border-success-dark/[0.85] dark:hover:bg-success-dark/[0.85] hover:bg-success/[0.85] hover:border-success/[0.85]'
      case 'warning':
        return 'bg-warning border-warning hover:bg-warning/[0.85] hover:border-warning/[0.85]'
      case 'danger':
        return 'bg-danger border-danger text-dark hover:bg-danger/[0.85] hover:border-danger/[0.85]'
      case 'transparent':
        return 'hover:bg-gray-100 hover:dark:bg-black-700 border-transparent text-black-1000 dark:text-gray-75'
      case 'primary':
      default:
        return 'bg-primary border-primary hover:bg-primary/[0.85] hover:border-primary/[0.85]'
    }
  }

  const getSizeClasses = () => {
    switch (size) {
      case 'lg':
        return 'px-6 py-3'
      case 'md':
        return 'py-2 px-4 text-sm'
      case 'sm':
        return 'py-1 px-3.5 text-[13px]'
      case 'xs':
        return 'py-1 px-3.5'
      case 'base':
      default:
        return 'py-2 px-5 text-[15px]'
    }
  }

  const getIconSize = () => {
    switch (props.size) {
      case 'lg':
        return 'w-5'
      case 'md':
        return 'w-3'
      case 'sm':
        return 'w-2'
      case 'xs':
        return 'w-1'
      case 'base':
      default:
        return 'w-4'
    }
  }

  return (
    <button
      onClick={props.onClick}
      type={type}
      disabled={props.disabled}
      className={clsx(
        `${getSizeClasses()} ${getColorClass()} border text-dark font-medium transition-all duration-300`,
        props.className,
        {
          btn: !props.withLabel,
          'rounded-full': props.rounded,
          'rounded-md': !props.rounded,
          'bg-transparent': props.outline,
          'opacity-50 pointer-events-none': props.disabled,
          'flex items-center gap-1.5': props.isLoading && !props.withLabel,
          'grid grid-flow-col !p-0': props.withLabel,
        },
      )}
    >
      {props.isLoading && !props.withLabel && (
        <span
          className={clsx(
            `${getIconSize()} animate-spin border-2 border-dark border-l-transparent rounded-full aspect-square inline-block align-middle`,
          )}
        />
      )}
      {!props.withLabel && props.title}

      {props.withLabel && (
        <Fragment>
          <p className="bg-white bg-opacity-20 w-10 h-full grid justify-center items-center rounded-l">
            {props.isLoading && (
              <span
                className={clsx(
                  `${getIconSize()} animate-spin border-2 border-dark border-l-transparent rounded-full aspect-square inline-block align-middle`,
                )}
              />
            )}

            {!props.isLoading && (
              <Icon
                name={iconType}
                className={clsx(`${getIconSize()} aspect-square mx-auto`)}
              />
            )}
          </p>
          <span className={getSizeClasses()}>{props.title}</span>
        </Fragment>
      )}
    </button>
  )
}
